export const PAGE_TITLE = "Blogcomments";
export const PAGE_SINGLE_TITLE = "Blogcomment";
export const LINK_URL = "blogcomments";
export const inputFields = {
    name: {
        type: "string",
        required: true,
        title: "Name",
        inputType: "text",
        slug: true,
    },
    email: {
        type: "string",
        required: false,
        title: "Email",
        inputType: "text",
    },
    blog: {
        type: "related",
        required: false,
        title: "Blog",
        inputType: "text",
        field: "name",
    },
    comment: {
        type: "html",
        required: false,
        title: "Comment",
        inputType: "text",
    },
    slug: {
        type: "slug",
        required: true,
        title: "Slug",
        inputType: "text",
    },
};
export const initialValues = {
    title: "",
};

export const view_all_table = [
    { name: "Name", value: "name" },
    // { name: "Image", value: "image", image: true },
];

export const SIDEBAR_OPTIONS = [
    {
        id: "name",
        field: "name",
        label: "Name",
        type: "string",
        search_type: "search",
        inputType: "text",
        condition: "",
    },
];
