import React, { useState, useEffect } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { useHistory } from "react-router-dom";
import FormComponents from "../common/FormComponents";
import moment from "moment";
import VariableOptions from "./VariableOptions";
import VariationTable from "./VariationTable";
import { CheckBox } from "../Form/Form";
function ProductForm({
  data,
  edit,
  submitForm,
  setFeaturedImage,
  setGallery,
  inputFields,
  initialValues,
  dropdown_options,
  loadOptions,
}) {
  let history = useHistory();
  let newItitianValues = {
    name: "",
    published_date: "",
    collections: [],
    description: "",
    media: [],
    regular_price: "",
    sale_price: "",
    tax: "",
    sku: "",
    in_stock: true,
    stock: 100,
    weight: "",
    length: "",
    width: "",
    height: "",
    is_variable_product: true,
    variation_attrs: [
      {
        label: "",
        options: [],
      },
    ],
    variations: [],
  };

  console.log("DATA TO SHOW", data);
  return (
    <div className="card-body">
      {edit ? (
        <div className="row">
          <Formik
            initialValues={{
              name: data.name,
              published_date: data.published_date,
              slug: data.slug,
              collections: data.collections,
              description: data.description,
              media: data.media,
              regular_price: data.regular_price,
              sale_price: data.sale_price,
              tax: data.tax,
              sku: data.sku,
              in_stock: data.in_stock,
              stock: data.stock,
              weight: data.weight,
              length: data.length,
              width: data.width,
              height: data.height,
              is_variable_product: data.is_variable_product,
              variation_attrs: data.variation_attrs,
              variations: data.variations,
            }}
            validationSchema={Yup.object({
              name: Yup.string().required("Required"),
              collections: Yup.array().required("Required").min(1),
              regular_price: Yup.number()
                .positive("Numbers Only")
                .required("Required"),
              sale_price: Yup.number()
                .positive("Numbers Only")
                .required("Required"),
              tax: Yup.number().positive("Numbers Only"),
              weight: Yup.number().positive("Numbers Only"),
              length: Yup.number().positive("Numbers Only"),
              height: Yup.number().positive("Numbers Only"),
              width: Yup.number().positive("Numbers Only"),
              stock: Yup.number().positive("Numbers Only"),
            })}
            onSubmit={async (values, { setSubmitting, resetForm }) => {
              setSubmitting(true);
              if (!values.is_variable_product) {
                values.variation_attrs = [];
                values.variations = [];
              }
              await submitForm(values);
              setSubmitting(false);
              resetForm(true);
            }}
          >
            {(formik) => {
              console.log(formik.values);
              return (
                <Form>
                  <FormComponents
                    formik={formik}
                    inputFields={inputFields}
                    setFeaturedImage={setFeaturedImage}
                    dropdown_options={dropdown_options}
                    setGallery={setGallery}
                    edit={edit}
                    loadOptions={loadOptions}
                  />
                  <div className="col-md-6">
                    <CheckBox
                      label="This product has options, like size or color"
                      name="is_variable_product"
                    ></CheckBox>
                  </div>
                  {formik.values.is_variable_product && (
                    <>
                      <VariableOptions
                        formik={formik}
                        item="variation_attrs"
                        inputFields={inputFields}
                      />
                      <VariationTable formik={formik} item="variations" />
                    </>
                  )}
                  <div className="row">
                    <div className="col-md-12 text-center m-3">
                      <button type="submit" className="btn btn-success">
                        {formik.isSubmitting
                          ? "Processing..."
                          : edit
                            ? "Edit"
                            : "Save"}
                      </button>
                      <a
                        className="btn btn-secondary m-3"
                        onClick={history.goBack}
                        href="#goback"
                      >
                        <i className="fa fa-angle-left"></i> Go Back
                      </a>
                    </div>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </div>
      ) : (
        <div className="row">
          <Formik
            initialValues={newItitianValues}
            validationSchema={Yup.object({
              name: Yup.string().required("Required"),
              collections: Yup.array().required("Required").min(1),
              regular_price: Yup.number()
                .positive("Numbers Only")
                .required("Required"),
              sale_price: Yup.number()
                .positive("Numbers Only")
                .required("Required"),
              tax: Yup.number().positive("Numbers Only"),
              weight: Yup.number().positive("Numbers Only"),
              length: Yup.number().positive("Numbers Only"),
              height: Yup.number().positive("Numbers Only"),
              width: Yup.number().positive("Numbers Only"),
              stock: Yup.number().positive("Numbers Only"),
            })}
            onSubmit={async (values, { setSubmitting, resetForm }) => {
              setSubmitting(true);
              if (!values.is_variable_product) {
                values.variation_attrs = [];
                values.variations = [];
              }
              await submitForm(values);
              setSubmitting(false);
              resetForm(true);
            }}
          >
            {(formik) => {
              console.log(JSON.stringify(formik.values));
              return (
                <Form>
                  <FormComponents
                    formik={formik}
                    inputFields={inputFields}
                    setFeaturedImage={setFeaturedImage}
                    dropdown_options={dropdown_options}
                    setGallery={setGallery}
                    edit={edit}
                    loadOptions={loadOptions}
                  />
                  <div className="col-md-6">
                    <CheckBox
                      label="This product has options, like size or color"
                      name="is_variable_product"
                    ></CheckBox>
                  </div>
                  {formik.values.is_variable_product && (
                    <>
                      <VariableOptions
                        formik={formik}
                        item="variation_attrs"
                        inputFields={inputFields}
                      />
                      <VariationTable formik={formik} item="variations" />
                    </>
                  )}
                  <div className="row">
                    <div className="col-md-12 text-center m-3">
                      <button type="submit" className="btn btn-success">
                        {formik.isSubmitting
                          ? "Processing..."
                          : edit
                            ? "Edit"
                            : "Save"}
                      </button>
                      <a
                        className="btn btn-secondary m-3"
                        onClick={history.goBack}
                        href="#goback"
                      >
                        <i className="fa fa-angle-left"></i> Go Back
                      </a>
                    </div>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </div>
      )}
    </div>
  );
}

export default ProductForm;
