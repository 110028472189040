import React, { useEffect } from "react";
import BreadCrumb from "../../components/template/BreadCrumb";
import Header from "../../components/template/Header";
import Spinner from "../../components/layout/Spinner";
import {
    inputFields,
    PAGE_TITLE,
    PAGE_SINGLE_TITLE,
    LINK_URL,
    SIDEBAR_OPTIONS,
} from "../../shared/enums/blogcomments_enum";
import SingleView from "../../components/common/SingleView";
import { useSingleBlogcomment } from "../../shared/hooks/UseBlogcomment";
const ViewBlogcomment = ({ match }) => {
    const [data] = useSingleBlogcomment(match.params.id);
    const { blogcomment_loading, blogcomment } = data;
    return (
        <div className="pace-done">
            <div>
                <Header />
                <BreadCrumb
                    title={PAGE_SINGLE_TITLE}
                    mainLinkTitle={PAGE_TITLE}
                    mainLinkUrl={LINK_URL}
                    activeLink="View"
                />
                {!blogcomment_loading ? (
                    blogcomment && (
                        <SingleView
                            data={blogcomment}
                            inputFields={inputFields}
                            label={PAGE_SINGLE_TITLE}
                            link={LINK_URL}
                            id={blogcomment._id}
                            SIDEBAR_OPTIONS={SIDEBAR_OPTIONS}
                        />
                    )
                ) : (
                    <div>
                        <Spinner />
                    </div>
                )}
            </div>
        </div>
    );
};

export default ViewBlogcomment;
