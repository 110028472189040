import React, { useState, useEffect } from "react";
import BreadCrumb from "../../components/template/BreadCrumb";
import Header from "../../components/template/Header";
import Spinner from "../../components/layout/Spinner";
import {
  inputFields,
  PAGE_TITLE,
  PAGE_SINGLE_TITLE,
  LINK_URL,
  SIDEBAR_OPTIONS,
} from "../../shared/enums/orders_enum";
import SingleView from "../../components/common/SingleView";
import { useSingleOrder, useUpdateOrder } from "../../shared/hooks/UseOrder";
import * as qs from "qs";
import {
  useSelectAllNotification,
  useUpdateNotification,
} from "../../shared/hooks/UseNotification";
import moment from "moment";
import { URI } from "../../domain/constant";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { SelectBox } from "../../components/Form/Form";

const ViewOrder = ({ match }) => {
  const queryParams = qs.parse(window.location.search.replace("?", ""));
  const [data, reloadData] = useSingleOrder(match.params.id);
  const { order_loading, order, edit_order_loading } = data;
  const [updateData] = useUpdateNotification();
  const [notificationParam, setNotificationParam] = useState(null);
  const [notificatiton_data, reloadNotificationData] =
    useSelectAllNotification();
  const [updateOrderData] = useUpdateOrder();
  useEffect(() => {
    if (notificationParam) {
      updateData(notificationParam, { is_read: true });
      reloadNotificationData();
    }
  }, [notificationParam]);
  console.log("QUERY PARAMS", queryParams);

  useEffect(() => {
    if (queryParams.notification) {
      setNotificationParam(queryParams.notification);
    }
  }, [queryParams && queryParams.notification]);

  const [editing, setEditing] = useState(false);

  const submitFormClicked = async (values) => {
    await updateOrderData(order._id, values);
    reloadData(order._id);
    setEditing(false);
  };

  return (
    <div className="pace-done">
      <div>
        <Header />
        <BreadCrumb
          title={PAGE_SINGLE_TITLE}
          mainLinkTitle={PAGE_TITLE}
          mainLinkUrl={LINK_URL}
          activeLink="View"
        />
        {!order_loading ? (
          order && (
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-9">
                  <div className="card">
                    <div className="card-header">
                      <h4 className="card-title">
                        {" "}
                        Order No #{order.order_id}{" "}
                      </h4>
                      <div className="card-body">
                        <table className="table table-striped">
                          <thead>
                            <tr>
                              <th> Item Summary </th>
                              <th> Qty </th>
                              <th>Price</th>
                              <th> Total Price </th>
                            </tr>
                          </thead>
                          <tbody>
                            {order.products &&
                              order.products.map((item) => {
                                return (
                                  <tr>
                                    <td>
                                      {" "}
                                      <img
                                        src={`${URI}${item.image}`}
                                        style={{
                                          height: "75px",
                                          width: "75px",
                                          objectFit: "contain",
                                        }}
                                      />{" "}
                                      {item.name}{" "}
                                    </td>
                                    <td> x {item.quantity} </td>
                                    <td>
                                      {" "}
                                      ₹{item.sale_price}{" "}
                                      <span
                                        style={{
                                          textDecoration: "line-through",
                                        }}
                                      >
                                        {item.regular_price}
                                      </span>{" "}
                                    </td>
                                    <td>
                                      {" "}
                                      ₹{item.quantity * item.sale_price}{" "}
                                    </td>
                                  </tr>
                                );
                              })}
                            <tr></tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="card">
                        <div className="card-header">
                          <h4 className="card-title"> Customer Details </h4>
                        </div>
                        <div className="card-body">
                          <div className="d-flex justify-content-between">
                            <div> Name </div>
                            <div>
                              <strong>
                                {order.customer && order.customer.name}
                              </strong>
                            </div>
                          </div>
                          <hr />
                          <div className="d-flex justify-content-between">
                            <div> Phone </div>
                            <div>
                              <strong>
                                {order.customer && order.customer.phone}
                              </strong>
                            </div>
                          </div>
                          <hr />
                          <div className="d-flex justify-content-between">
                            <div> Email </div>
                            <div>
                              <strong>
                                {order.customer && order.customer.email}
                              </strong>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="card">
                        <div className="card-header">
                          <h4 className="card-title"> Delivery Address </h4>
                        </div>
                        <div className="card-body">
                          <div className="d-flex justify-content-between">
                            <div> Address 1</div>
                            <div>
                              <strong>
                                {order.address && order.address.address_1}
                              </strong>
                            </div>
                          </div>
                          <hr />
                          <div className="d-flex justify-content-between">
                            <div> Address 2</div>
                            <div>
                              <strong>
                                {order.address && order.address.address_2}
                              </strong>
                            </div>
                          </div>
                          <hr />
                          <div className="d-flex justify-content-between">
                            <div> Landmark</div>
                            <div>
                              <strong>
                                {order.address && order.address.landmark}
                              </strong>
                            </div>
                          </div>
                          <hr />
                          <div className="d-flex justify-content-between">
                            <div> City</div>
                            <div>
                              <strong>
                                {order.address && order.address.landmark}{" "}
                                {order.address && order.address.state}{" "}
                                {order.address && order.address.pin}
                              </strong>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="card">
                    <div className="card-header">
                      <h4 className="card-title"> Status </h4>
                    </div>
                    <div className="card-body">
                      {editing ? (
                        <div>
                          <Formik
                            initialValues={{
                              status: order.status,
                            }}
                            validationSchema={Yup.object({
                              status: Yup.string().required("Required"),
                            })}
                            onSubmit={async (
                              values,
                              { setSubmitting, resetForm }
                            ) => {
                              setSubmitting(true);
                              await submitFormClicked(values);
                              resetForm();
                              setSubmitting(false);
                            }}
                          >
                            {(formik) => {
                              console.log(formik);
                              return (
                                <Form>
                                  <div className="row">
                                    <div className="col-md-6">
                                      <SelectBox name="status">
                                        <option value=""> --none-- </option>
                                        <option value="PENDING">PENDING</option>
                                        <option value="PROCESSING">
                                          PROCESSING
                                        </option>
                                        <option value="READY TO DISPATCH">
                                          READY TO DISPATCH
                                        </option>
                                        <option value="DELIVERED">
                                          DELIVERED
                                        </option>
                                        <option value="REFUNDED">
                                          REFUNDED
                                        </option>
                                        <option value="CANCELLED">
                                          CANCELLED
                                        </option>
                                        <option value="FAILED">FAILED</option>
                                        <option value="RETURNED">
                                          RETURNED
                                        </option>
                                        <option value="INTRANSPORT">
                                          IN TRANSPORT
                                        </option>
                                        <option value="OUTFORDELIVERY">
                                          OUT FOR DELIVERY
                                        </option>
                                      </SelectBox>
                                    </div>
                                    <div className="col-md-6">
                                      <button
                                        type="submit"
                                        className="btn btn-warning"
                                      >
                                        {" "}
                                        Update{" "}
                                      </button>
                                    </div>
                                  </div>
                                </Form>
                              );
                            }}
                          </Formik>
                        </div>
                      ) : (
                        <div className="d-flex justify-content-between">
                          <div>{order.status}</div>
                          <div>
                            {" "}
                            <button
                              onClick={() => setEditing(true)}
                              className="btn btn-warning"
                            >
                              {" "}
                              Change{" "}
                            </button>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="card">
                    <div className="card-header">
                      <h4 className="card-title"> Order - Is Paid </h4>
                    </div>
                    <div className="card-body">
                      {editing ? (
                        <div>
                          <Formik
                            initialValues={{
                              is_paid: order.is_paid ? true : false,
                            }}
                            validationSchema={Yup.object({
                              is_paid: Yup.string().required("Required"),
                            })}
                            onSubmit={async (
                              values,
                              { setSubmitting, resetForm }
                            ) => {
                              setSubmitting(true);
                              await submitFormClicked(values);
                              resetForm();
                              setSubmitting(false);
                            }}
                          >
                            {(formik) => {
                              console.log(formik);
                              return (
                                <Form>
                                  <div className="row">
                                    <div className="col-md-6">
                                      <SelectBox name="is_paid">
                                        <option value=""> --none-- </option>
                                        <option value={true}>YES</option>
                                        <option value={false}>NO</option>
                                      </SelectBox>
                                    </div>
                                    <div className="col-md-6">
                                      <button
                                        type="submit"
                                        className="btn btn-secondary"
                                      >
                                        {" "}
                                        Update{" "}
                                      </button>
                                    </div>
                                  </div>
                                </Form>
                              );
                            }}
                          </Formik>
                        </div>
                      ) : (
                        <div className="d-flex justify-content-between">
                          <div>{order && order.is_paid ? "YES" : "NO"}</div>
                          <div>
                            {" "}
                            <button
                              onClick={() => setEditing(true)}
                              className="btn btn-secondary"
                            >
                              Change
                            </button>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="card">
                    <div className="card-header">
                      <h4 className="card-title"> Order Summary </h4>
                    </div>
                    <div className="card-body">
                      <div className="d-flex justify-content-between">
                        <div> Order Date </div>
                        <div>
                          <strong>
                            {moment(order.order_date).format("DD-MMM-YYYY")}
                          </strong>
                        </div>
                      </div>
                      <div className="d-flex justify-content-between">
                        <div> Sub Total </div>
                        <div>
                          <strong>{order.sub_total}</strong>
                        </div>
                      </div>
                      <div className="d-flex justify-content-between">
                        <div> Tax </div>
                        <div>
                          <strong>{order.tax}</strong>
                        </div>
                      </div>
                      <div className="d-flex justify-content-between">
                        <div> Delivery Charges </div>
                        <div>
                          <strong>{order.delivery_charges}</strong>
                        </div>
                      </div>
                      <div className="d-flex justify-content-between">
                        <div> Discount </div>
                        <div>
                          <strong>-{order.discount}</strong>
                        </div>
                      </div>
                      <hr />
                      <div className="d-flex justify-content-between">
                        <div> Total Amount </div>
                        <div>
                          <strong>₹{order.total_amount}</strong>
                        </div>
                      </div>
                    </div>
                  </div>
                  {
                    //   order.shipping_details && (
                    //   <div className="card">
                    //     <div className="card-header">
                    //       <h4 className="card-title"> Shipment Tracking </h4>
                    //     </div>
                    //     <div className="card-body">
                    //       <div className="d-flex justify-content-between">
                    //         <div> Shipping Order ID </div>
                    //         <div>
                    //           <strong>{order.shipping_details.order_id}</strong>
                    //         </div>
                    //       </div>
                    //       <div className="d-flex justify-content-between">
                    //         <div> AWB </div>
                    //         <div>
                    //           <strong>{order.shipping_details.awb}</strong>
                    //         </div>
                    //       </div>
                    //       <div className="d-flex justify-content-between">
                    //         <div> Current Status </div>
                    //         <div>
                    //           <strong>
                    //             {order.shipping_details.current_status}
                    //           </strong>
                    //         </div>
                    //       </div>
                    //       <div className="d-flex justify-content-between">
                    //         <div> Shipment Status </div>
                    //         <div>
                    //           <strong>
                    //             {order.shipping_details.shipment_status}
                    //           </strong>
                    //         </div>
                    //       </div>
                    //       <div className="d-flex justify-content-between">
                    //         <div> Courier Name </div>
                    //         <div>
                    //           <strong>
                    //             {order.shipping_details.courier_name}
                    //           </strong>
                    //         </div>
                    //       </div>
                    //       <hr />
                    //       <h4 className="card-title"> Order Tracking </h4>
                    //       {order.shipping_details.scans &&
                    //         order.shipping_details.scans.map((item, index) => {
                    //           return (
                    //             <div>
                    //               <div className="d-flex justify-content-between">
                    //                 <div> Date </div>
                    //                 <div>
                    //                   <strong>{item.date}</strong>
                    //                 </div>
                    //               </div>
                    //               <div className="d-flex justify-content-between">
                    //                 <div> Activity </div>
                    //                 <div>
                    //                   <strong>{item.activity}</strong>
                    //                 </div>
                    //               </div>
                    //               <div className="d-flex justify-content-between">
                    //                 <div> Location </div>
                    //                 <div>
                    //                   <strong>{item.location}</strong>
                    //                 </div>
                    //               </div>
                    //             </div>
                    //           );
                    //         })}
                    //     </div>
                    //   </div>
                    // )
                  }
                </div>
              </div>
            </div>
          )
        ) : (
          <div></div>
        )}
      </div>
    </div>
  );
};

export default ViewOrder;
